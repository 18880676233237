import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Gist from 'react-gist';
import '../assets/css/style.css';

const GDPRPage = () => (
  <Layout>
    <SEO title="GDPR - Emailkick" />
   
    <div className="max-width-4 mx-auto terms">
    
    <h2>GDPR Policy</h2>
    <p className="mt2">This service is committed to protecting your privacy.<br /><br /></p>
    <h3>Information collected and how it is used</h3>
    <p>When you sign up, the service collects your email and a password for identification purpose. These credentials allow you access the managed service and provides an identifier to map everything related to you on the service to. Without this, you will not be able to access the service. We also use your email to communicate with you and send you weekly reports about your domains.
    <br /><br />
We also ask you provide your access keys to your Amazon SES/SNS. This is used to get information related to the account domains, set an endpoint (webhook) where mail events can be sent to and send quick mail from the Emailkick dashboard. In plain terms, we collect this information to be able to provide the necessary reporting and analytics.
<br /><br />

We use third party tools like Google Analytics (analytics) and Stripe (payment). These tools use tracking technologies and collect data like IP address, Device identifiers, Web browser information, Page view statistics, Browsing history, Usage information and Log data (e.g. access times, hardware and software information). This data is used to analyse usage, provide support for you, protect against fraudulent, illegal or harmful actions and facilitate payment.
<br /><br />
When you login, a tiny piece of information (called cookies) is stored on your browser for authentication purpose. These cookies only last during the browsing session and does not contain any sensitive data about you.
<br /><br />
Your data is yours and you can delete your account anytime. Simply login to your dashboard and go to your profile settings to do so. All data related to you will be completely removed from the system. You can also export your logs any time.</p>



<h3>Information Disclosure</h3>
<p>We will not give, sell, rent, or loan any information related to you to any third party. We may provide non-personal statistics about the service use and traffic patterns to reputable third-party vendors, but these statistics will include no personally identifying information. Here are trusted entities your data is shared with:
<br /><br />
Stripe: Stripe is a leader in online payments. Emailkick uses Stripe to manage payments. When you enter your card details, it is securely sent to Stripe with your email. Stripe uses this to process the necessary payment.
Google Analytics: Browser, device and usage data for analytics. No personal data is shared
Google Cloud, Amazon EC2: Source, database and Search hosting
<br /><br />

All collected data are protected by using reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.
<br /><br />
You can request access to information Emailkick has about you by sending a mail to hello@emailkick.com. We will send you a copy electronically.</p>

<h3>Minors</h3>
<p>We do not knowingly collect or solicit Personal Data from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any Personal Data about yourself to us.</p>


<h3>Changes to Policy</h3>
<p>We may change or supplement this Privacy Policy from time to time and we will post revised versions of this Policy on the Site.</p>

<h3>Contact</h3>
<p>All questions and concerns about your privacy can be sent to @Emailkick or hello@emailkick.com.</p>


</div>




  </Layout>
)

export default GDPRPage
